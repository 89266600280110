import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  constructor(public auth: AuthService, private activatedRoute: ActivatedRoute) {}
  
  ngOnInit(): void {
    // Check if the user is already authenticated; if not, redirect to Auth0
    this.auth.isAuthenticated$.subscribe(isAuthenticated => {
      if (!isAuthenticated) {
        const authorizationParams = { ...this.activatedRoute.snapshot.queryParams };

        this.auth.loginWithRedirect({
          authorizationParams: authorizationParams,
          appState: {
            target: '/profile',  // Adjust to your desired redirect after login
          }
        });
      }
    });
  }

}