import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { HighlightModule } from 'ngx-highlightjs';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
  standalone: true,
  imports: [HighlightModule, AsyncPipe, NgIf],
})
export class ProfileComponent implements OnInit {
  profileJson: string = null;

  user: any;

  rawToken: any;
  token: any;

  constructor(public auth: AuthService) {}

  ngOnInit() {
    this.auth.user$.subscribe(
      (profile) => (this.profileJson = JSON.stringify(profile, null, 2))
    );

    this.auth.getAccessTokenSilently().subscribe((token) => {
      this.rawToken = token;

      const base64Payload = token.split('.')[1];

      // Decode from base64
      const payload = atob(base64Payload.replace(/-/g, '+').replace(/_/g, '/'));

      // Parse JSON
      return this.token = JSON.stringify(JSON.parse(payload), null, 2);
    });

      this.auth.user$.subscribe(userData => {
        this.user = userData
      })
  }
}
